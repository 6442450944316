import React from "react"

import { Link } from "gatsby"

import Background from "./background"
import styles from "./preview.module.scss"

const PreviewComplex = ({ post, type }) => {
  const node = post.node
  const slug = post.node.fields.slug
  return (
    <li className={styles.post}>
      <Link to={`/${type}/${slug}`}>
        <div className={styles.postInner}>
          <Background
            src={`${type}/${slug}/${slug}.jpg`}
            className={styles.image}
            verticalPosition={node.frontmatter.verticalPosition}
          ></Background>
          <div className={styles.info}>
            <h2 className={styles.title}>{node.frontmatter.title}</h2>
            {type !== "collections" ? (
              <div className={styles.metrics}>
                {type !== "writing" && <div>{node.frontmatter.date}</div>}
                {type !== "videos" && <div>{node.wordCount.words} words</div>}
                {type !== "videos" && (
                  <div>
                    {node.timeToRead}{" "}
                    {node.timeToRead > 1 ? "minutes" : "minute"} to read
                  </div>
                )}
              </div>
            ) : (
              <div className={styles.metrics}>
                <div>{node.frontmatter.subtitle}</div>
              </div>
            )}
          </div>
        </div>
      </Link>
    </li>
  )
}

export default PreviewComplex
