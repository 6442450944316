import React, { useMemo } from "react"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"

const Background = ({ src, className, verticalPosition = 50, ...props }) => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { sourceInstanceName: { eq: "images" } }) {
        edges {
          node {
            relativePath
            childImageSharp {
              img: fluid(maxWidth: 850) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  const match = useMemo(
    () => data.allFile.edges.find(({ node }) => src === node.relativePath),
    [data, src]
  )

  return (
    <BackgroundImage
      className={className}
      style={{
        backgroundSize: "cover",
        backgroundPosition: `50% ${verticalPosition}%`,
        backgroundRepeat: "repeat-y",
      }}
      fluid={match?.node.childImageSharp.img}
    >
      {props.children}
    </BackgroundImage>
  )
}

export default Background
